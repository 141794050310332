import { styled } from "@mui/material/styles";
import { Container } from "@mui/system";
import { useCallback, useState } from "react";
import HeaderComp from "../headerComp/headerComp";
import ImageComp from "../imageComp/imageComp";
import { MAVINTERNATIONAL } from "../../utils/const";

const StyledConatiner = styled(Container)({
  marginTop: "1em",
});

const GalleryWrapComponent = () => {
  const isInternationalURL = window.location.host.includes(MAVINTERNATIONAL)
    ? true
    : false;
  const [images, setImages] = useState([]);
  const [year, setYear] = useState("Select year");
  const [selectedAlbum, setSelectedAlbum] = useState("");

  const handleSetAlbum = useCallback(
    (images: any) => {
      setSelectedAlbum(images);
    },
    [images],
  );
  const handleImages = (imageList: any) => {
    setImages(imageList);
  };

  return (
    <StyledConatiner maxWidth="lg">
      <HeaderComp
        handleImages={handleImages}
        year={year}
        setYear={setYear}
        selectedAlbum={selectedAlbum}
        setSelectedAlbum={handleSetAlbum}
        isInternationalURL={isInternationalURL}
      />
      <ImageComp
        imageList={images}
        year={year}
        album={selectedAlbum}
        isInternationalURL={isInternationalURL}
      />
    </StyledConatiner>
  );
};
export default GalleryWrapComponent;
