import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl as getSignedUrlFromS3 } from "@aws-sdk/s3-request-presigner";

import gAxios from "../../hooks/useAxios";
import { GET_ALBUM, GET_IMAGES } from "../../utils/const";
const {
  S3Client,
  PutObjectCommand,
  ListObjectsV2Command,
  DeleteObjectsCommand,
  S3,
} = require("@aws-sdk/client-s3");

const bucketName = window.location.href.includes("mavvidyashram.com")
  ? "mavschool"
  : "mavinternationalbucket";

// Configure the S3 client
const s3Client = new S3Client({
  region: process.env.REACT_APP_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  },
});

export const getGallery = async (year: string | number) => {
  try {
    let params = {
      year: year,
    };
    const response = await gAxios.get(GET_ALBUM, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error", error);
  }
};

export const getImages = async (year: string, album: string) => {
  try {
    let params = {
      year: year,
      album: album,
    };
    const response = await gAxios.get(GET_IMAGES, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error", error);
  }
};

export const listFoldersInS3 = async (prefixString?: string) => {
  const input = prefixString
    ? {
        Bucket: bucketName,
        Delimiter: "/",
        Prefix: `${prefixString}/`,
      }
    : {
        Bucket: bucketName,
        Delimiter: "/",
      };
  try {
    const command = new ListObjectsV2Command(input);

    const response = await s3Client.send(command);
    // console.log(response);
    if (response.CommonPrefixes) {
      const folders = response.CommonPrefixes.map((prefix: { Prefix: any }) =>
        prefix.Prefix.replace(`${prefixString}/`, "").replace("/", ""),
      );
      // remove the folder path from the folder name and remove / after folder name

      folders.forEach((folder: any) => {
        // console.log(folder.replace("2024/", ""));
      });
      //console.log("Folders:", folders);
      return folders;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error listing folders:", error);
  }
};

// get all the images in a folder
export const getImagesInFolder = async (year: string, folderName: string) => {
  const input = {
    Bucket: bucketName,
    Prefix: `${year}/` + folderName + "/",
  };
  try {
    const command = new ListObjectsV2Command(input);
    const response = await s3Client.send(command);
    const images = response.Contents.map(
      (content: { Key: any }) => content.Key,
    );

    const signedUrlPromises = images.map((image: any) => {
      const command = new GetObjectCommand({
        Bucket: bucketName,
        Key: image,
        ResponseContentType: "image/jpeg",
      });
      return getSignedUrl(s3Client, command);
    });

    return await Promise.all(signedUrlPromises)
      .then((signedUrlList) => {
        // console.log("Images:", signedUrlList);
        return signedUrlList;
      })
      .catch((error) => {
        console.error("Error generating signed URLs:", error);
        throw error;
      });
  } catch (error) {
    console.error("Error listing images:", error);
  }
};

async function getSignedUrl(s3Client: any, command: GetObjectCommand) {
  try {
    const signedUrl = await getSignedUrlFromS3(s3Client, command, {
      expiresIn: 3600,
    });
    return signedUrl;
  } catch (error) {
    console.error("Error generating signed URL:", error);
    throw new Error("Could not generate signed URL");
  }
}
